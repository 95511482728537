import './App.css';
import { useState, useEffect, useRef } from 'react';
import ButtonAppBar from './Header';
import SimpleBottomNavigation from './Footer.js';
import BasicDateRangePicker from './DatePicker.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { waveform } from 'ldrs';
import Typography from '@mui/material/Typography';
import EnhancedTable from './SortedSplunkTable';
import Cookies from 'js-cookie';
import WifiIcon from '@mui/icons-material/Wifi';
import dayjs from 'dayjs';
import { decodeToken } from 'react-jwt';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import CircularWithValueLabel from './CircularDeterminate.js';

const serverUrl = process.env.REACT_APP_SERVER_ENDPOINT || 'http://localhost:8000';

waveform.register();
const theme = createTheme({
  palette: {
    primary: {
      main: '#00274C',
    },
  },
});

function createData(Radius_Timestamp, Building, Radius_AccessPoint, Radius_SSID) {
  return { Radius_Timestamp, Building, Radius_AccessPoint, Radius_SSID };
}

function App() {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'week'));
  const [endDate, setEndDate] = useState(dayjs());
  const [isLoading, setLoading] = useState(false);
  const [splunkRows, setSplunkRows] = useState([]);
  const [searchClick, setSearchClick] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [jwtToken, setJWTToken] = useState(Cookies.get('jwt'));
  const [uniqname, setUniqname] = useState('');
  const [hasBeenRendered, setHasBeenRendered] = useState(false);
  const [takingLong, setTakingLong] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const checkToken = async () => {
      let token = Cookies.get('jwt');
      if (!token) {
        redirectToLogin(); // No token found, redirect to login
        return;
      }
      const decodedToken = decodeToken(token);
      const expiryTime = new Date(decodedToken.exp * 1000);
      if (expiryTime < new Date()) {
        // Token has expired, try to refresh it
        const result = await refreshToken();
        if (!result) {
          redirectToLogin(); // If refresh failed, redirect to login
          return;
        }
        token = result;
      }
      setJWTToken(token);
      setUniqname(decodeToken(token).sub);
    };

    checkToken();
  }, []);

  const redirectToLogin = () => {
    // Clear cookies and redirect to login
    Cookies.remove('jwt');
    Cookies.remove('user');
    window.location.href = `${serverUrl}/api/login`;
  };

  const refreshToken = async () => {
    try {
      const response = await fetch(`${serverUrl}/api/refresh-token`, {
        method: 'POST',
        credentials: 'include', // Important to include cookies
      });
      if (!response.ok) throw new Error('Failed to refresh token');
      const data = await response.json();
      Cookies.set('jwt', data.token);
      setJWTToken(data.token);
      return data.token;
    } catch (err) {
      console.error('Error refreshing token:', err);
      return null;
    }
  };

  useEffect(() => {
    if (hasBeenRendered) {
      let ignoreStaleRequest = false;
      setSplunkRows([]); // Reset before search.
      setLoading(true);
      setErrorMsg('');
      setTakingLong(false); // Reset the long request indicator

      let fetchUrl = `${serverUrl}/api/create-job/?startTime=${startDate.unix()}&endTime=${endDate.unix()}`;
      const jwtToken = Cookies.get('jwt');

      const controller = new AbortController();
      const signal = controller.signal;

      // Timeout to display "taking longer" message after 45 seconds
      const longRequestTimeoutId = setTimeout(() => {
        setTakingLong(true);
      }, 45000);

      // Timeout to abort request after 3 minutes
      const requestTimeoutId = setTimeout(() => {
        controller.abort();
      }, 180000);

      fetch(fetchUrl, {
        method: 'GET',
        headers: {
          'Authorization': jwtToken,
        },
        signal,
      })
        .then((response) => {
     
          if (!response.ok) {
            if (response.status === 401) {
              redirectToLogin();
            }
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((createJobData) => {
          if (!ignoreStaleRequest) {

            let jobSid = createJobData.sid
            let progressUrl = `${serverUrl}/api/get-loading-progress/?sid=${jobSid}`;
            var timer = setInterval(() => {
              fetch(progressUrl, {
                method: 'GET',
                headers: {
                  'Authorization': jwtToken,
                },
                signal,
              })
                .then((response) => response.json())
                .then((jobData) => {
                  setLoadingProgress(jobData.loading_progress)
                  console.log(jobData.loading_progress)
                  if (jobData.loading_progress === 100) {
                    clearTimeout(longRequestTimeoutId); // Clear the "taking long" timeout
                    clearTimeout(requestTimeoutId); // Clear the request timeout          
                    setLoadingProgress(0)
                    clearInterval(timer)
               
                    const rowCount = Object.keys(jobData.results).length;
                
                    for (let row = 0; row < rowCount; row++) {
                      const dataRow = jobData.results[row];

                      setSplunkRows((splunkRows) => [
                        ...splunkRows,
                        createData(
                          dataRow.Radius_Timestamp,
                          dataRow.Building,
                          dataRow.Radius_AccessPoint,
                          dataRow.Radius_SSID,
                        ),
                      ]);
                      
                    }
                    setLoading(false);
                    setSearchClick(true); // Ensure searchClick is only reset after the data has been processed.
                    ignoreStaleRequest = true;
                   
                  }
                })
            }, 2000);

          }
      
        })
        .catch((error) => {
          console.error(error);
          if (error.name === 'AbortError') {
            setErrorMsg('Request timed out');
          } else {
            setErrorMsg(`Server Unreachable: ${error}`);
          }
          setLoading(false);
          clearTimeout(longRequestTimeoutId); // Clear the "taking long" timeout
        });

      return () => {
        clearTimeout(longRequestTimeoutId); // Cleanup the "taking long" timeout if the component unmounts
        clearTimeout(requestTimeoutId); // Cleanup the request timeout if the component unmounts
        controller.abort(); // Abort the fetch request if the component unmounts to clean up
      };
    }
    setHasBeenRendered(true); // Prevents running on first load
  }, [startDate, endDate]);

  let output;
  if (isLoading) {
    output = (
      <div>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
          <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
            <CircularWithValueLabel progress={loadingProgress}/>
            {takingLong && (
              <Typography variant="h5" padding={2}>
                Please hang on, processing your request
              </Typography>
            )}
          </Stack>
        </Box>
      </div>
    );
  } else if (errorMsg) {
    output = (
      <Box>
        <Stack direction="column" sx={{ width: '100%' }} spacing={2} alignItems="center" padding={2}>
          <DoNotDisturbOnIcon sx={{ fontSize: 80 }} />
          <Typography variant="h6">{errorMsg}</Typography>
          <Box mt={1}>
            <Alert severity="info">
              Contact <a href="https://its.umich.edu/help">ITS Service Center</a> for assistance.
            </Alert>
          </Box>
        </Stack>
      </Box>
    );
  } else if (searchClick && splunkRows.length === 0) {
    output = (
      <Box>
        <Stack direction="column" sx={{ width: '100%' }} spacing={2} alignItems="center" padding={2}>
          <BrowserNotSupportedIcon sx={{ fontSize: 80 }} />
          <Typography>No results found.</Typography>
          <Box mt={1}>
            <Alert severity="info">
              Contact <a href="https://its.umich.edu/help">ITS Service Center</a> for assistance.
            </Alert>
          </Box>
        </Stack>
      </Box>
    );
  } else if (splunkRows.length > 0) {
    output = <EnhancedTable splunkRows={splunkRows} isLoading={isLoading} errorMsg={errorMsg} startDate={startDate} endDate={endDate} />;
  }

  return (
    <div className="App" style={{ height: '100%' }}>
      <ThemeProvider theme={theme}>
        <Box display="flex" flexDirection="column">
          <ButtonAppBar uniqname={uniqname} setJWTToken={setJWTToken} />
          <Box flex={1} display="flex" flexDirection="column" justifyContent="center">
            <Container maxWidth="lg">
              <Stack spacing={3}>
                <Box mt={2} padding={3}>
                  <Typography variant="h4">View Your Wireless Connection History on U-M Campuses.</Typography>
                </Box>
                <Box mt={1}>
                  <Alert severity="warning">You can retrieve results from the past 90 days.</Alert>
                </Box>
                <Box mt={1}>
                  <Alert severity="warning">
                    Please note: Devices may connect to different access points as they roam, which can make it appear
                    that you are switching locations frequently, even when you are not physically moving. This is a
                    normal behavior of wireless networks.
                  </Alert>
                </Box>

                <BasicDateRangePicker
                  sendStart={setStartDate}
                  sendEnd={setEndDate}
                  setSearchClick={setSearchClick}
                  searchDisable={isLoading}
                />
                {!searchClick && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                    <WifiIcon sx={{ fontSize: 150, color: 'grey' }} />
                  </Box>
                )}
                {output}
              </Stack>
            </Container>
          </Box>
          <SimpleBottomNavigation />
        </Box>
      </ThemeProvider>
    </div>
  );
}

export default App;